.parentContainer {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  background: #D4AF37;
  min-height: 300px;
  color:#333333;
}
.Container {
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 50px 5px;
}
.title {
  color: #0f0f0f;
  font-family: "Red Hat Display";
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 30px;
}
.subtitle {
    color: #0f0f0f;
    font-family: "Red Hat Display";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    max-width: 400px;
    margin-bottom: 40px;
}
.contact {
  color: #0f0f0f;
  font-family: "Red Hat Display";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  max-width: 400px;
  margin-bottom: 10px;
  text-decoration: none; /* Removes underline */
  transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition on hover */
}

.contact:hover {
  color: #333333; /* Change color on hover */
  transform: scale(1.05); /* Slightly scale the link on hover */
}


@media screen and (max-width:800px) {
  .parentContainer{
    flex-direction: column;
    max-width: 100vw;
    overflow: hidden;
    padding: 0px 20px;
  }
  .Container{
    padding: 20px 5px;
  }
}