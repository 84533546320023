@import 'swiper/scss';
@import 'swiper/scss/autoplay';


.posters-slider img {
  width: 100%;
}

.posters-slider a {
  width: 100%;
}

.posterContainer {
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.posterTitle {
  width: 100%;
  max-width: 1200px;
  color: #D4AF37;
  font-family: "Red Hat Display";
  font-size: 60px;
  font-weight: 500;
  margin-bottom: 30px;
}

.posters-slider {
  width: 100%;
  max-width: 1200px; // Ensure the slider doesn't get too wide on larger screens

  .swiper {
    width: 100%;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 18px;
    // Ensure the slides have a responsive size
    // Do not set a fixed width here; let Swiper control the width
  }

  img {
    max-width: 100%;
    // height: 20rem;
    object-fit: cover; // Adjust this as needed
  }
}

// Media query for mobile devices
@media (max-width: 768px) {

  // .posters-slider .swiper {
  //   width: 100vw;
  //   margin-left: 0.5rem;
  // }

  .posters-slider img {
    width: 100%;
  }

  .posters-slider a {
    width: 96%;
  }

  .posterTitle {
    text-align: center;
    margin-bottom: none;
  }
}

