.galleryParent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.galleryTitle {
  color: #D4AF37;
  font-family: "Red Hat Display";
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 30px;
}
.galleryContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 100px;
}

/* style.module.css */

.galleryParent :global(.slick-dots) {
  bottom: -74px;
}

.galleryParent :global(.slick-list .slick-active) {
  margin-right: 20px;
  transition: all 1s ease-in-out;
}

.galleryParent :global(.slick-slider) {
  max-height: 450px;
}

/* 
.galleryParent :global(.slick-list .slick-current + .slick-active) {
  transform: scale(1.2);
} 
*/

.galleryParent :global(.slick-dots .slick-active) {
  transform: scale(3);
  background: #1f1f1f;
}

@media screen and (max-width: 800px) {
  .galleryTitle {
    width: 100%;
    text-align: center;
    margin: 0px;
    margin-bottom: 30px;
  }

  .galleryParent :global(.slick-dots) {
    bottom: -44px;
  }

  .galleryParent :global(.slick-list .slick-active) {
    transform: scale(0.8);
    transition: all 1s ease-in-out;
  }

  .galleryParent :global(.slick-list .slick-current + .slick-active) {
    transform: scale(1.2);
  }

  .galleryParent :global(.slick-dots .slick-active) {
    transform: scale(3);
    background: #1f1f1f;
  }
  
}
