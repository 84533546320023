/**
 * UI Initiative Stories Slider 1.0.0
 *
 * Instagram-like Stories Slider Made With Swiper
 *
 * https://uiinitiative.com
 *
 * Copyright 2022-2023 UI Initiative
 *
 * Released under the UI Initiative Regular License
 *
 * Released on: November 13, 2023
 */

:root {
  --stories-slider-z-shadows: 999;
  --stories-slider-z-pagination: 50;
  --stories-slider-z-navigation: 200;
  --stories-slider-z-user: 300;
  --stories-slider-z-actions: 300;
  --stories-slider-z-content: 100;
  --stories-slider-slide-bg-color: #000;
}

.stories-slider {
  padding-top: 44px;
  padding-bottom: 44px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.stories-slider > .swiper > .swiper-wrapper > .swiper-slide:not(.swiper-slide-next, .swiper-slide-prev, .swiper-slide-active) {
  opacity: 0;
}
.stories-slider > .swiper > .swiper-wrapper > .swiper-slide-next + .swiper-slide {
  opacity: 1;
}
.stories-slider .swiper {
  height: 100%;
  width: 100%;
}
.stories-slider .swiper .swiper .swiper-slide {
  border-radius: 8px;
  overflow: hidden;
  background-color: var(--stories-slider-slide-bg-color);
}
.stories-slider .swiper .swiper-slide-shadow-left,
.stories-slider .swiper .swiper-slide-shadow-right {
  z-index: var(--stories-slider-z-shadows) !important;
}
.stories-slider .swiper .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, rgb(0, 0, 0), rgba(0, 0, 0, 0.5));
}
.stories-slider .swiper .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, rgb(0, 0, 0), rgba(0, 0, 0, 0.5));
}
.stories-slider.stories-slider-perspective .swiper .swiper,
.stories-slider.stories-slider-perspective .swiper-slide-shadow-left,
.stories-slider.stories-slider-perspective .swiper-slide-shadow-right {
  transform: translate3d(0, 0, calc(-1 * var(--swiper-cube-translate-z)));
  transform-origin: 0% 0% calc(-1 * var(--swiper-cube-translate-z));
}

.stories-slider-button {
  position: absolute;
  z-index: var(--stories-slider-z-navigation);
  top: 0;
  height: 100%;
  user-select: none;
  -webkit-user-select: none;
}
.stories-slider-button::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition-duration: 200ms;
}
.stories-slider-button:active::before {
  opacity: 0.25;
}
.stories-slider-button-prev {
  left: 0;
  width: 30%;
}
.stories-slider-button-prev::before {
  background-image: linear-gradient(to right, #000, rgba(0, 0, 0, 0));
}
.stories-slider-button-next {
  right: 0;
  width: 70%;
}
.stories-slider-button-next::before {
  background-image: linear-gradient(to left, #000, rgba(0, 0, 0, 0));
}

.stories-slider-pagination {
  position: absolute;
  left: 8px;
  right: 8px;
  top: 8px;
  display: flex;
  justify-content: space-between;
  z-index: var(--stories-slider-z-pagination);
  pointer-events: none;
  transform: translate3d(0, 0, 0);
}
.stories-slider-pagination-bullet {
  width: 100%;
  flex-shrink: 10;
  border-radius: 999px;
  height: 2px;
  background: rgba(255, 255, 255, 0.35);
  position: relative;
  overflow: hidden;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.35);
}
.stories-slider-pagination-bullet span {
  position: absolute;
  background: #fff;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 999px;
  transform: translateX(-100%);
  transition-timing-function: linear;
}
.stories-slider-pagination-bullet-viewed span {
  transform: translateX(0%);
  transition-duration: 0ms !important;
}
.stories-slider-pagination-bullet + .stories-slider-pagination-bullet {
  margin-left: 2px;
}

a.stories-slider-user,
.stories-slider-user a {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition-duration: 200ms;
}
a.stories-slider-user:active,
.stories-slider-user a:active {
  opacity: 0.55;
}

.stories-slider-user {
  display: flex;
  align-items: center;
  position: absolute;
  top: 18px;
  left: 12px;
  height: 32px;
  z-index: var(--stories-slider-z-user);
  color: #fff;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
}
.stories-slider-user-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 12px;
}
.stories-slider-user-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}
.stories-slider-user-name {
  font-size: 14px;
  font-weight: bold;
  margin-right: 12px;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.35);
}
.stories-slider-user-date {
  font-size: 14px;
  font-weight: normal;
  opacity: 0.55;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.35);
}

.stories-slider-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  right: 10px;
  top: 18px;
  height: 32px;
  z-index: var(--stories-slider-z-actions);
  transition-duration: 200ms;
  user-select: none;
  -webkit-user-select: none;
}

.stories-slider-close-button {
  margin-left: 18px;
  width: 22px;
  height: 22px;
  appearance: none;
  border: none;
  outline: none;
  box-shadow: none;
  display: block;
  position: relative;
  cursor: pointer;
  padding: 0;
  background: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
  -webkit-user-select: none;
}
.stories-slider-close-button::before, .stories-slider-close-button::after {
  content: "";
  width: 125%;
  height: 2px;
  background: #fff;
  border-radius: 999px;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.35);
}
.stories-slider-close-button::before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.stories-slider-close-button::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.stories-slider-close-button:active {
  opacity: 0.55;
}

.stories-slider-content {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: var(--stories-slider-z-content);
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  -webkit-user-select: none;
}
.stories-slider-content > img,
.stories-slider-content > video {
  width: 100%;
  height: 100%;
  object-position: center;
  display: block;
  border: none;
  outline: none;
  background: none;
  background-color: transparent;
}
.stories-slider-content > img {
  object-fit: cover;
}
.stories-slider-content > video {
  object-fit: contain;
}