.brandsContainer {
  overflow: hidden;
  position: relative;
}

.brandsTitle {  color: #d4af37;
  font-family: 'Red Hat Display';
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.marquee {
  overflow: hidden;
  position: relative;
  max-width: 1200px;
  margin: auto;
}

.marquee div,
.marqueeReverse div {
  display: flex;
  animation: marquee 25s linear infinite; /* Adjusted for a faster loop */
  gap: 2rem; /* Reduced gap for a smoother appearance */
  white-space: nowrap; /* Ensure content stays in one line */
  align-items: center;
}

.marqueeReverse div {
  animation-direction: reverse; /* Reverse the animation for the second marquee */
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  } /* Updated to -100% for a full loop */
}

span {
  display: inline-block; /* Ensure images are aligned inline */
  padding: 0 1rem; /* Adjusted padding for tighter spacing */
}

span img {
  display: block; /* Ensure images are block level */
}

@media (max-width: 768px) {
  .marqueeReverse div {
    animation-direction: reverse; /* Reverse the animation for the second marquee */
  }
  span img {
    display: block; /* Ensure images are block level */
    max-height: 100px;
  }
  .marquee div,
  .marqueeReverse div {
    gap: 0; /* Reduced gap for a smoother appearance */
  }
}

@media (max-width: 400px) {
  .marqueeReverse div {
    animation-direction: reverse; /* Reverse the animation for the second marquee */
  }
  span img {
    display: block; /* Ensure images are block level */
    max-height: 70px;
  }
}
