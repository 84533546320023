  @import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Italiana&display=swap');

  body {
    font-family: 'Red Hat Display', sans-serif;
    background-image:url("https://wlbs-crm.s3.ap-south-1.amazonaws.com/vogueshots/public/assets/images/background.JPG");
  }
*{
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}
body{
  background-image:url("https://wlbs-crm.s3.ap-south-1.amazonaws.com/vogueshots/public/assets/images/background.JPG");
}
.posterDivSection{
  max-height: 500px;
  width: 100%;
}