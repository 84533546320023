.ScrollerParent {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  margin-top:10rem;
}
.scrollerContainer {
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.mobHide{
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.scrollderImageContainer {
  max-width: 350px;
  max-height: 650px;
  width: 350px;
  height: 650px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  border:solid #D4AF37;
}
.scrollderImageContainer img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.Overlay {
  visibility: hidden;
  transition: all 1s ease-in-out;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 20px;
  text-transform: capitalize;
  color: #D4AF37;
  font-size: 40px;
}
.scrollderImageContainer:hover .Overlay {
  visibility: visible;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0), #000);
  cursor: pointer;
}
.scrollerVideo {
  width: 100%;
  height: 100%;
}
.desktopHide{
  display: none;
}
@media screen and (max-width:800px) {
  .ScrollerParent{
    padding: 0px 5px;
  }
  .scrollderImageContainer {
    max-height: 650px;
    width: 350px;
    height: 650px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
  }
  .scrollerContainer {
    gap: 30px;
    padding: 5px 25px;
  }
  .desktopHide{
    display: flex;
  }
  .mobHide{
    display: none
  }
  .Overlay {
    visibility: visible;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0), #000);
    cursor: pointer;
    font-size: 30px;
  }
}