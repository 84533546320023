.descriptionparent {
  margin: 60px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.descriptionContainer {
  max-width: 1200px;
  width: 100%;
  display: flex;
  gap: 50px;
}
.descriptionContainer2 {
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  gap: 50px;
  justify-content: space-between;
}
.imageContainer {
  width: 105rem;
  height: 40rem;
}
.imageContainer img:global {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.DescriptionBox {
  width: 65%;
}
.descriptionTitle {
  color: #d4af37;
  font-family: 'Red Hat Display';
  font-size: 60px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 20px;
}
.description {
  color: #d1d1d1;
  font-family: 'Red Hat Display';
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.mobTitle {
  display: none;
  color: #d4af37;
  font-family: 'Red Hat Display';
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 20px;
}
@media screen and (max-width: 900px) {

  .imageContainer img:global {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .description {
    color: #d1d1d1;
    font-family: 'Red Hat Display';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .mobTitle {
    display: block;
    width: 100%;
    text-align: center;
  }
  .descriptionTitle {
    display: block;
    font-size: 30px;
  }
  .descriptionContainer {
    flex-direction: column;
    max-width: 100vw;
  }
  .descriptionContainer2 {
    flex-direction: column;
    max-width: 100vw;
  }
  .DescriptionBox {
    width: 100%;
    padding: 40px;
  }
  .imageContainer {
    max-width: 100vw;
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }
  .imageContainer img:global {
    max-width: 100vw;
  }
  .descriptionContainer {
    margin: 0px;
    gap: 25px;
  }
  .descriptionContainer2 {
    margin: 10px 0px;
    gap: 25px;
  }
  .mobTitle {
    display: none;
    margin-bottom: 0px;
  }
  .descriptionparent {
    margin-top: 0;
  }
}

.descriptionBoxVideo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
