/* HomepageBannerNew.css */

/* Desktop styles */
.HomepageBannerNew_desktop-container {
  width: 100%;
  height: 70.3125em;
}

.HomepageBannerNew_image-container {
  position: relative;
  height: 100%;
}

.HomepageBannerNew_image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(45%); /* Makes the image darker */
}

.HomepageBannerNew_image-text {
  position: absolute;
  bottom: 135px;
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: 40px;
  width: 100%;
  text-align: center;
}

/* Hover effects */
.HomepageBannerNew_image-container:hover img {
  filter: none; /* Removes the filter, showing the image in full color */
}

.HomepageBannerNew_image-container {
  cursor: pointer;
}

.HomepageBannerNew_icon-link {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.HomepageBannerNew_image-container:hover .HomepageBannerNew_icon-link {
  display: block;
}

.HomepageBannerNew_icon {
  margin: 0 10px;
  width: 40px;
  height: 40px;
}

/* Desktop Swiper styles */
.HomepageBannerNew_desktop-container .swiper,
.HomepageBannerNew_desktop-container .swiper-wrapper,
.HomepageBannerNew_desktop-container .swiper-slide {
  height: 100%;
}

.HomepageBannerNew_desktop-container .swiper-button-prev,
.HomepageBannerNew_desktop-container .swiper-button-next {
  color: gold;
}

.HomepageBannerNew_desktop-container .swiper-pagination {
  color: gold;
}

/* Mobile styles */
.HomepageBannerNew_mobile-container {
  display: none; /* Hide mobile container by default */
}

@media (max-width: 900px) {
  .HomepageBannerNew_desktop-container {
    display: none; /* Hide desktop view on mobile */
  }

  .HomepageBannerNew_mobile-container {
    display: block; /* Show mobile view on mobile */
    margin-bottom: 60px;
  }

  .swiper {
    width: 100vw;
    height: 100vh;
  }

  .HomepageBannerNew_slide-content {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .HomepageBannerNew_slide-content img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: none; /* Ensure mobile images always remain colorful */
  }

  .HomepageBannerNew_slide-text {
    position: absolute;
    bottom: 85px;
    width: 100%;
    left: 0;
    text-align: center;
    color: white;
    font-size: 30px;
    padding: 5px;
    border-radius: 5px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: 90%;
    color: gold;
    font-family: 'flo-icons';
    background-size: contain;
    background-repeat: no-repeat;
    height: 40px;
    width: 40px;
  }

  .swiper-pagination-fraction {
    color: gold;
  }

  .swiper-button-prev {
    background-image: url('https://wlbs-crm.s3.ap-south-1.amazonaws.com/vogueshots/public/assets/icons/icons8-arrow-200.png'); /* Your custom previous arrow icon */
  }

  .swiper-button-next {
    background-image: url('https://wlbs-crm.s3.ap-south-1.amazonaws.com/vogueshots/public/assets/icons/icons8-arrow-100.png'); /* Your custom next arrow icon */
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    display: none;
  }

  .swiper-pagination-line {
    position: absolute;
    bottom: 95px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: gold;
    margin: 0 15px;
    z-index: 1;
  }
}