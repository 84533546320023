.instaParent {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: max-content;
}
.instaGrid {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  flex-wrap: wrap;
  margin-bottom: 80px;
}
.instaGrid img:global {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.ImageInstaContainer {
  padding: 3px;
  max-width: 400px;
  max-height: 400px;
  width: 400px;
  height: 400px;
}
.followOnInstaBtn {
  color: #212121;
  text-align: center;
  font-family: 'Red Hat Display';
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 37px;
  background: #d4af37;
  padding: 10px 40px;
  margin-bottom: 80px;
  cursor: pointer;
  user-select: none;
  color: black;
}

.followOnInstaBtnLink{
color: black;
text-decoration: none;
}

@media screen and (max-width: 800px) {
  .ImageInstaContainer {
    max-width: 150px;
    max-height: 150px;
    min-height: 150px;
    border: 1px solid #fff;
  }

  .followOnInstaBtn {
    font-size: 20px;
  }

  .instaGrid .ImageInstaContainer:nth-child(9) {
    display: none;
  }
}
