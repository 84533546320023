.galleryParent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.galleryTitle {
  color: #d4af37;
  font-family: 'Red Hat Display';
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 30px;
}
.galleryContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 100px;
}
.DestinationContainer {
  font-size: 30px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  color: #fff;
  position: relative;
}
.DestinationContainer img:global {
  object-fit: cover;
  width: 100%;
  height: 35rem;
}
.destinationName {
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 100;
}
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #0000005c;
}
.galleryParent :global(.slick-track) {
  display: flex;
  gap: 45px;
}

.galleryParent :global(.slick-dots) {
  bottom: -85px;
}

.galleryParent :global(.slick-dots .slick-active) {
  transform: scale(2);
  background: transparent;
}

.galleryParent :global(.slick-dots li button:before) {
  color: gold;
}
@media screen and (max-width: 800px) {
  .galleryTitle {
    width: 100%;
    text-align: center;
    margin: 0px;
    margin-bottom: 30px;
  }

  .DestinationContainer img:global {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .galleryParent :global(.slick-dots .slick-active) {
    transform: scale(2);
    background: transparent;
  }

  .galleryParent :global(.slick-dots) {
    bottom: -45px;
  }
  .overlay {
    display: none;
  }

  .DestinationContainer img:global {
    object-fit: cover;
    width: 100%;
    height: 25rem;
  }

  .galleryParent :global(.slick-track) {
    display: flex;
    gap: 0px;
  }
}

@media screen and (max-width: 400px) {
  .slick-dots li {
    width: 12px;
  }
}
