.containerSlider {
  width: 100%;
  height: 100%;
  margin-bottom: 100px;
  position: relative;
}

.nextArrow,
.prevArrow {
  position: absolute;
  width: fit-content;
  height: 50px;
  z-index: 10;
  bottom: -100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nextArrow {
  right: 10%; /* Consistent positioning */
}

.prevArrow {
  left: 10%; /* Consistent positioning */
}

.slideCountDisplay {
  text-align: center;
  margin-top: 60px;
  color: #d4af37;
  /* Additional styling as needed */
}

.nextArrow img,
.prevArrow img {
  height: auto; /* Maintain aspect ratio */
  width: 50px; /* Consistent width for better visibility */
  transition: transform 0.2s; /* Smooth scaling on hover */
}

.nextArrow:hover img,
.prevArrow:hover img {
  transform: scale(1.1); /* Interactive hover effect */
}

@media screen and (max-width: 1024px) {
  .nextArrow,
  .prevArrow {
    bottom: -80px; /* Adjusted for medium screens */
  }

  .nextArrow img,
  .prevArrow img {
    width: 40px; /* Slightly smaller arrows for medium screens */
  }
}

@media screen and (max-width: 800px) {
  .nextArrow,
  .prevArrow {
    bottom: -60px; /* Raised for better visibility on small screens */
  }

  .nextArrow img,
  .prevArrow img {
    width: 35px; /* Prevent arrows from becoming too small */
  }
}

@media screen and (max-width: 500px) {
  .slideCountDisplay {
    text-align: center;
    margin-top: 25px;
    color: #d4af37;
    /* Additional styling as needed */
  }
}
