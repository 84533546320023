.parentContainer {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  margin: 40px 0px;
}
.Container {
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  padding: 40px 0px;
  box-sizing: border-box;
  margin: 20px 0px;
}
.Col {
  color: #fff;
  font-family: "Red Hat Display";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  align-items: center;
  padding: 30px 0px;
  width: 100%;

}
.divider {
  height: 200px;
  border: 1px solid #D4AF37;
  background: #D4AF37;
}
.displayFont{
    font-size: 60px;
    font-weight: 600;
}
@media screen and (max-width:800px) {
  .parentContainer{
    margin: 0px;
  }
  .Container{
    flex-direction: column;
  }
  .divider{
    height: 2px;
    width: 80%;
    margin: 0px auto;
  }
}