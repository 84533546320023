.aboutParent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.aboutContainer {
  width: 100%;
  max-width: 1200px;
}
.title {
  color: white;
  font-family: 'Red Hat Display';
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: 900px) {
  .title {
    text-align: center;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 900px) {
  .aboutParent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10rem;
  }
}
