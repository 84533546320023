.template-slider-swiper {
  width: 40%;
}

.template-slider-component-button-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.template-slider-component-button-image-container .swiper-pagination-bullet {
  width: 8px;
  padding: 0;
  background-color: black;
}

.template-slider-component-button-container button {
  background-color: black;
  color: #d4af37;
  border: 1px solid #d4af37; /* Add thin golden borders */
  padding: 8px 18px; /* Adjust padding to compensate for the border thickness */
  margin: 5px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.template-slider-component-button-container button:hover {
  transform: scale(1.1);
  background-color: #d4af37;
  color: black;
}

.template-slider-component-button-container .active {
  background-color: #d4af37;
  color: black;
}

@media (max-width: 900px) {
  .template-slider-swiper {
    height: unset !important;
  }
}
