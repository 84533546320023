.headerParent {
  display: flex;
  height: 150px;
  max-width: 100%;
  box-sizing: border-box;
  justify-content: center;
}
.Container {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  gap: 50px;
  align-items: center;
  padding: 10px 0px;
  width: 100%;
  max-width: 1200px;
}
.logoContainer {
  height: 100%;
  display: flex;
  align-items: center;
}
.HeaderTextContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.contactButtonLink {
  color: #000;
  text-decoration: none;
}

.HeaderTextContainer a {
  text-decoration: none;
}
.HeaderText {
  color: white;
  font-family: 'Red Hat Display';
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  position: relative;
  display: inline-block;
  overflow: hidden; /* Ensures the pseudo-element does not exceed the parent's boundary */
  padding-bottom: 5px; /* Adds some space for the underline */
}

.HeaderText::after {
  content: '';
  display: block;
  width: 0;
  height: 2px; /* Thinner underline */
  background: #d4af37; /* Gold color */
  transition: width 0.4s ease, opacity 0.3s ease; /* Smooth transition for width and opacity */
  position: absolute;
  bottom: 0; /* Position adjusted to align with the bottom of the text */
  left: 50%;
  transform: translateX(-50%); /* Center the underline */
  opacity: 0; /* Start with a transparent underline */
}

.HeaderText:hover::after {
  width: 100%;
  opacity: 1; /* Fully visible underline on hover */
}
.contactButton {
  padding: 10px 20px;
  border-radius: 37px;
  background-color: #d4af37;
  color: #212121;
  text-align: center;
  font-family: 'Red Hat Display';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  user-select: none;
  cursor: pointer;
  color: black;
}
.contactButton:active,
.HeaderText:active {
  transform: scale(0.98); /* Slightly scale down the element when active */
}

.activeScreen {
  position: relative; /* Needed for positioning the pseudo-element */
  display: inline-block; /* This will make the element respect the full width of its content */
  color: white; /* Assuming you want the same text color */
  font-family: 'Red Hat Display'; /* Keeping font consistent */
  font-size: 20px; /* Adjust based on your design */
  font-weight: 600; /* Adjust based on your design */
  padding-bottom: 5px; /* Adds some space for the underline, adjust as needed */
}

.activeScreen::after {
  content: '';
  display: block;
  width: 100%; /* Ensures the underline spans the full width of the element */
  height: 2px; /* Thinner underline, adjust as needed */
  background: #d4af37; /* Gold color for the underline */
  position: absolute;
  bottom: 0; /* Aligns the underline at the bottom of the element */
  opacity: 1; /* Fully visible underline for active state */
}

.hamburger {
  display: none;
}
.crossButton {
  display: none;
}

.vogueShotLogo {
  width: 10rem;
  height: auto;
}

.menuItem {
  position: relative;
}

.megaMenuContainer {
  position: relative;
}

.megaMenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #0f0f0f;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  padding: 10px;
  z-index: 1000;
  min-width: 200px;
}

@media (min-width: 900px) {
  .megaMenuContainer:hover .megaMenu {
    display: block;
  }
}

@media (max-width: 899px) {
  .megaMenu {
    position: static;
    display: block;
    box-shadow: none;
    padding: 0 0 0 20px;
  }
  
  .megaMenuContainer {
    display: block;
  }
}

.megaMenu .HeaderText {
  display: block;
  padding: 5px 10px;
  white-space: nowrap;
}

.megaMenu .HeaderText:hover {
  background-color: #0f0f0f;
}

@media screen and (max-width: 900px) {
  .headerParent {
    height: 0px;
  }
  .crossButton {
    display: block;
    position: absolute;
    top: 40px;
    right: 20px;
    width: 40px;
    height: 40px;
    color: #d4af37;
    font-size: 30px;
    z-index: 100;
  }
  .headerParent {
    max-width: 100%;
    position: relative;
  }

  .Container {
    padding-right: 30px;
    position: absolute;
    top: 60px;
    bottom: 0;
    background: transparent;
    z-index: 1000;
  }
  .logoContainer {
    width: 8rem;
    height: auto;
  }
  .logoContainer a:global {
    width: 100%;
    height: 100%;
  }
  .logoContainer img:global {
    width: 100%;
    height: 100%;
  }
  .hamburger {
    display: block;
  }
  .HeaderTextContainer {
    display: none;
    transition: all 1s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    background: #000;
    height: 100vh;
    min-width: 100vw;
    z-index: 1000;
    /* padding: 200px 0px; */
    max-width: 100vw;
    max-height: 100vh;
  }

  body.is-scroll-locked {
    overflow: hidden;
  }
}

.megaMenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #0f0f0f;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  padding: 10px;
  z-index: 1000;
  min-width: 200px;
}

@media (min-width: 900px) {
  .megaMenuContainer:hover .megaMenu {
    display: block;
  }
}

.megaMenu .HeaderText {
  display: block;
  padding: 5px 10px;
  white-space: nowrap;
}

.megaMenu .HeaderText:hover {
  background-color: #0f0f0f;
}

@media screen and (max-width: 900px) {
  .headerParent {
    height: auto;
  }
  .crossButton {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    color: #d4af37;
    font-size: 30px;
    z-index: 100;
  }
  .headerParent {
    max-width: 100%;
    position: relative;
  }

  .Container {
    padding: 10px 20px;
    justify-content: space-between;
    align-items: center;
  }
  .logoContainer {
    width: 8rem;
    height: auto;
  }
  .hamburger {
    display: block;
  }
  .HeaderTextContainer {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    background: #000;
    height: 100vh;
    width: 100vw;
    padding: 60px 20px 20px;
    overflow-y: auto;
    z-index: 1000;
  }

  .megaMenuContainer {
    width: 100%;
  }

  .megaMenu {
    position: static;
    display: none;
    box-shadow: none;
    padding: 0 0 0 20px;
    background-color: transparent;
    width: 100%;
  }

  .megaMenu.open {
    display: block;
  }

  .menuItem {
    width: 100%;
  }

  .HeaderText {
    font-size: 18px;
    padding: 10px 0;
  }

  body.is-scroll-locked {
    overflow: hidden;
  }
}