.enhancedWrapper {
  margin: 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contentContainer,
.contentContainerAlt {
  max-width: 1200px;
  width: 100%;
  display: flex;
  gap: 40px;
  justify-content: space-between;
}
.contentContainerAlt {
  flex-direction: row-reverse;
}
.mediaWrapper {
  width: calc(40% - 20px);
  height: auto;
}
.mediaImage,
.mediaVideo {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}
.descriptionBlock {
  width: 60%;
}
.title {
  color: #c19a6b; /* Softer gold */
  font-family: 'Red Hat Display', sans-serif;
  font-size: 60px;
}
.descriptionText {
  color: #e8e8e8; /* Lighter grey for better readability */
  font-family: 'Red Hat Display', sans-serif;
  font-size: 30px;
  line-height: 1.5;
}
.mobileTitle {
  display: none;
}

.subTitle {
  font-weight: 100;
  color: #c19a6b;
  margin-bottom: 15px;

}

.mediaImage,
.mediaVideo {
  object-fit: cover;
  border-radius: 100%; /* Makes the image/video round */
}

@media screen and (max-width: 900px) {
  .contentContainer,
  .contentContainerAlt {
    flex-direction: column;
    gap: 20px;
  }

  .descriptionText {
    color: #e8e8e8; /* Lighter grey for better readability */
    font-family: 'Red Hat Display', sans-serif;
    font-size: 20px;
    line-height: 1.5;
  }
  .mediaWrapper {
    width: 100%;
    height: auto;
  }
  .descriptionBlock,
  .mediaWrapper {
    padding: 20px;
    width: auto;
  }
  .mobileTitle {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    color: #c19a6b;
    font-size: 28px;
  }
  .title {
    display: none;
  }
  .enhancedWrapper {
    margin: 80px 0;
  }
}
