.galleryParent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.galleryTitle {
  color: #d4af37;
  font-family: 'Red Hat Display';
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 30px;
}
.galleryContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 100px;
}

/* style.module.css */

.galleryParent :global(.slick-dots) {
  bottom: -74px;
}

.galleryParent :global(.slick-list .slick-active) {
  transform: scale(0.8);
  transition: all 1s ease-in-out;
}

.galleryParent :global(.slick-list .slick-current + .slick-active) {
  transform: scale(1.2);
}

.galleryParent :global(.slick-dots .slick-active) {
  transform: scale(3);
  background: #1f1f1f;
}
.two_by_six .template-content-image,
.four_by_six .template-content-image,
.six_by_four .template-content-image,
.four_by_six .template-content-image {
  object-fit: contain;
}
.demo-stories-avatar img {
  width: 10rem !important;
  height: 10rem !important;
}

.demo-stories-name {
  font-size: 25px !important;
  width: max-content;
}

.swiper-slide .stories-slider-user {
  display: flex;
  flex-direction: row;
}

.demo-stories-avatar {
  position: relative;
  border-radius: 50%;
  padding: 4px;
  background-image: unset I !important;
  border: solid thin gold;
}

.check {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
}

.check h1 {
  color: #d4af37;
  font-family: 'Red Hat Display';
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.demo-stories {
  display: flex;
  overflow-x: auto;
  width: 90%; /* Adjust the width as needed */
  gap: 10%; /* Space between items */
}

.demo-stories a {
  flex-shrink: 0; /* Prevent flex items from shrinking */
}

/* Optional: Hide scrollbar appearance */
.demo-stories::-webkit-scrollbar {
  display: none;
}

.demo-stories {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@media screen and (max-width: 800px) {
  .galleryTitle {
    width: 100%;
    text-align: center;
    margin: 0px;
    margin-bottom: 30px;
  }

  .galleryParent :global(.slick-dots) {
    bottom: -44px;
  }

  .galleryParent :global(.slick-list .slick-active) {
    transform: scale(0.8);
    transition: all 1s ease-in-out;
  }

  .galleryParent :global(.slick-list .slick-current + .slick-active) {
    transform: scale(1.2);
  }

  .galleryParent :global(.slick-dots .slick-active) {
    transform: scale(3);
    background: #1f1f1f;
  }
}

.demo-stories {
  display: flex;
  overflow-x: scroll;
  scrollbar-width: thin; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  gap: 10%;
  padding: 20px 0;
  cursor: grab; /* Change cursor to grab on hover */
}

.demo-stories::-webkit-scrollbar {
  height: 8px; /* For Webkit browsers */
}

.demo-stories::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.demo-stories:active {
  cursor: grabbing; /* Change cursor to grabbing when dragging */
}

.demo-stories a {
  flex-shrink: 0;
  transition: transform 0.2s ease-in-out;
}

/* Hover effect */
.demo-stories a:hover {
  transform: scale(1.05);
}

/* Responsive adjustments */
@media screen and (max-width: 800px) {
  .demo-stories a {
    width: 15% !important;
    min-width: 15%; /* Show 2 items on smaller screens */
  }

  .demo-stories {
    gap: 2rem !important;
    width: 70% !important;
  }

  .demo-stories-avatar img {
    width: 64px !important;
    height: 64px !important;
  }

  .demo-stories-name {
    font-size: 13px !important;
  }
}

@media screen and (max-width: 500px) {
  /* .demo-stories a {
    min-width: 100%; /* Show 1 item on very small screens 
  } */
}

.demo-stories {
  display: flex;
  overflow-x: scroll;
  gap: 10%;
  cursor: grab;
}

.demo-stories:active {
  cursor: grabbing;
}

.demo-stories a {
  flex: 0 0 auto; /* Prevent flex items from growing or shrinking */
  width: 10rem;
}

/* Hide scrollbar for a cleaner look */
.demo-stories::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 600px) {
  /* .demo-stories a {
    width: 50%; /* Show fewer items on smaller screens 
  } */
}

@media screen and (max-width: 600px) {
  .demo-stories {
    gap: 1rem !important;
    width: 85% !important;
  }
}

@media screen and (max-width: 400px) {
  /* .demo-stories a {
    width: 100%; /* Show one item at a time on very small screens 
  } */
  .demo-stories {
    gap: 2rem !important;
    width: 80% !important;
  }

  .demo-stories a {
    width: 28% !important;
    min-width: 15%;
  }
}
